<template>
  <div>
    <div class="row page-title align-items-center">
      <div class="col-sm-4 col-xl-6">
        <h4 class="mb-1 mt-0">Create Campaign</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <ValidationObserver
              v-slot="{ handleSubmit, invalid, touched }"
              ref="createForm"
            >
              <form
                class="authentication-form"
                @submit.prevent="handleSubmit(createCampaign)"
              >
                <div class="row">
                  <div class="col-md-12">
                    <TextInput
                      v-model="campaign.title"
                      name="title"
                      rules="required"
                      label="Title"
                    />
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <ValidationProvider
                        v-slot="{ failed, errors }"
                        rules="required"
                        name="description"
                      >
                        <label>Description</label>
                        <textarea
                          v-model="campaign.description"
                          name="description"
                          class="form-control"
                          rows="3"
                          :class="{ 'is-invalid': failed }"
                        ></textarea>
                        <b-form-invalid-feedback v-if="errors.length">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
                <b-form-group>
                  <b-button
                    variant="primary"
                    :disabled="loadingCreate || (invalid && touched)"
                    type="submit"
                  >
                    <b-spinner v-if="loadingCreate" small />
                    <span v-else>Create</span>
                  </b-button>
                </b-form-group>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      campaign: {
        title: '',
        description: '',
      },
      loadingCreate: false,
    }
  },

  methods: {
    createCampaign() {
      this.loadingCreate = true

      this.$store
        .dispatch('campaign/create', this.campaign)
        .then(() => {
          this.$router.push({ name: 'business.campaigns.index' })
          this.loadingCreate = false
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.createForm.setErrors(err.response.data.errors)
          }
          this.loadingCreate = false
        })
    },
  },
}
</script>
